<template>
  <div id="categories-list">
    <!-- titulo -->
    <h2 class="m-2">YAQUI - SEGUIMIENTO ORDEN</h2>
    <v-card>
      <div id="map" style="height: 350px">
        <MglMap
          @load="onMapLoad"
          :center.sync="center"
          :accessToken="accessToken"
          :mapStyle.sync="mapStyle"
          :zoom="zoom"
        >
          <MglMarker v-for="(marker, ii) in coordinates" :coordinates="marker.coordinates" :key="marker.id">
            <div slot="marker">
              <img :src="ii < coordinates.length - 1 ? '' : '/yaqui/driver.png'" height="40px" />
              <!-- <img v-if="ii == 0" src="/yaqui/drivera.png" height="25px" /> -->
            </div>
            <!-- <MglPopup>
              <div>
                <span style="font-size: 25px !important">{{
                  $moment(marker.comentario).utc().format('DD-MM-YYYY hh:mm:ss')
                }}</span>
              </div>
            </MglPopup> -->
          </MglMarker>
          <MglMarker v-for="(marker2, ii) in marcas" :coordinates="marker2.coordinates" :key="marker2.id">
            <div slot="marker">
              <img :src="marker2.icon" height="30px" />
            </div>
            <!-- <MglPopup>
              <div>
                <span style="font-size: 25px !important">{{
                  $moment(marker2.subtitle).utc().format('DD-MM-YYYY hh:mm:ss')
                }}</span>
              </div>
            </MglPopup> -->
          </MglMarker>
          <!-- <MglGeojsonLayer
          type="fill"
          sourceId="1"
          layerId="layerId"
        /> -->
        </MglMap>
      </div>
    </v-card>
    <card>
      <!-- <label>{{ resDataGereral }}</label> -->
      <br />
      <label> <span class="bold">Orden Nº :</span> {{ dataOrder.idd }}</label
      ><br />
      <label>
        <span class="bold">Estado :</span>
        {{
          resDataGereral.conductor_id == null
            ? 'Asignando driver...'
            : Number(resDataGereral.estado) == null
            ? '(--)'
            : Number(resDataGereral.estado) + 1 < JSON.parse(dataOrder.puntos).length
            ? Number(resDataGereral.estado) + 1 == 0
              ? 'El driver se dirige al punto A'
              : Number(resDataGereral.estado) + 1 == 1
              ? 'El driver se dirige al punto B'
              : Number(resDataGereral.estado) + 1 == 2
              ? 'El driver se dirige al punto C'
              : Number(resDataGereral.estado) + 1 == 3
              ? 'El driver se dirige al punto D'
              : Number(resDataGereral.estado) + 1 == 4
              ? 'El driver se dirige al punto E'
              : Number(resDataGereral.estado) + 1 == 5
              ? 'El driver se dirige al punto F'
              : '.-.'
            : Number(resDataGereral.estado) + 1 == JSON.parse(dataOrder.puntos).length
            ? 'Entregando...'
            : Number(resDataGereral.estado) == 99
            ? 'Orden finalizada.'
            : '-:-'
        }}</label
      ><br />

      <label> <span class="bold">Cliente :</span> {{ dataOrder.nombre }}</label
      ><br />
      <label><span class="bold">'Método de pago :</span> {{ dataOrder.metodo_pago }}</label
      ><br />
      <template v-for="(it, i) of puntosOrden">
        <label class="mr-2"
          ><span class="bold">Dirección Nº:{{ i + 1 + ': ' }}</span></label
        >
        <label>{{ it.direccion }}</label
        ><br />
      </template>
    </card>
  </div>
</template>

<script>
import { mdiCheckBold, mdiDeleteOutline, mdiPencil, mdiPlusCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'

import { MglGeojsonLayer, MglGeolocateControl, MglMap, MglMarker, MglNavigationControl, MglPopup } from 'vue-mapbox'
var CryptoJS = require('crypto-js')

// import UserListAddNew from './UserListAddNew.vue'
// import userStoreModule from '../userStoreModule'

// import useUsersList from './useUsersList'

export default {
  components: {
    MglMap,
    MglMarker,
    MglGeojsonLayer,
    MglNavigationControl,
    MglGeolocateControl,
    MglPopup,
  },
  data() {
    return {
      icons: { mdiDeleteOutline, mdiPencil, mdiPlusCircle, mdiCheckBold },
      items: [],
      table: 'seguimiento_orden',
      tableColumns: [
        { text: 'id', value: 'id', sortable: true },
        { text: 'Fecha Orden', value: 'fecha_orden', sortable: true },
        { text: 'Fecha Finalizado', value: 'fecha_finalizado', sortable: true },
        { text: 'Total', value: 'total', sortable: true },
        { text: 'Estado', value: 'estado', sortable: true },

        { text: 'Acciones', value: 'acciones', sortable: false },

        // {id:0, name:}
      ],
      loading: false,
      totalItems: 0,
      isDialogVisible: false,
      row: {},
      modalTitle: 'Nueva Orden',
      customersList: [],
      categoriesList: [],
      subcategoriesList: [],
      commerceList: [],
      formHasErrors: false,
      errorMessages: '',
      form: ref(null),
      valid: ref(true),
      isSnackbarVisible: false,
      message: '',
      messageColor: 'primary',
      icon: '',
      snackbar: {
        color: 'success',
        icon: 'check_circle',
        mode: 'multi-line',
        title: 'Success',
        text: 'That worked, hoorah.',
        visible: true,
      },
      statusList: [{ nombre: 'Activo' }, { nombre: 'Inactivo' }],

      accessToken: 'pk.eyJ1IjoicGVkaWRvc3lhIiwiYSI6ImNsMnNkNGl4ODBqaDgzZW8zN3VvY2c1YWMifQ.sOFdwHG62peplh1SCnpxgQ',
      mapStyle: 'mapbox://styles/mapbox/streets-v11',
      stateList: [{ name: 'Activo' }, { name: 'Pendiente' }],

      geojson: {
        type: 'FeatureCollection',
        features: [
          {
            properties: { name: 'south park' },
            geometry: {
              coordinates: [-95.2358865737915, 38.96194491354418],
            },
          },
          {
            properties: { name: 'Buford M Watson, Jr. Park' },
            geometry: {
              coordinates: [-95.23893356323242, 38.97148792405487],
            },
          },
        ],
      },
      layerId: 'firstLayer',
      sourceId: 'firstSource',
      coordinates: [],
      //coordinates: this.geojson,
      center: [-86.108508886867, 11.912447380368315],
      zoom: 8,

      id: 0,
      Mymap: null,
      dataOrder: [],
      ////////////
      puntosOrden: [],
      tiempo: null,
      distancia: null,
      marcas: [],
      lat: '',
      lng: '',
      resDataGereral: {},
    }
  },
  async mounted() {
    this.id = this.$route.params.id
    var bytes = CryptoJS.AES.decrypt(this.id.toString(), 'YaquiApp123456')
    this.id = bytes.toString(CryptoJS.enc.Utf8)
    // this.id = CryptoJS.AES.encrypt(idd, 'YaquiApp123456').toString();
    await this.getData()

    for (const item of this.items) {
      // console.log('item-->', item)
      // console.log(item)
      // let vector = [].concat(item.ubicacion)
      let data = {
        icon: '~/yaqui/driver.png',
        coordinates: JSON.parse('[' + item.lng + ', ' + item.lat + ']'),
        id: item.id,
        comentario: item.fecha,
      }
      // console.log(data)
      //vector["coordinates"]=item.ubicacion
      this.coordinates.push(data)
    }
    // console.log('#####', this.coordinates[0].coordinates)
    this.center = this.coordinates[0].coordinates
  },
  methods: {
    async showDataOrderInMap(mapa) {
      try {
        //this.puntosOrden = JSON.parse(this.orden.puntos)
        console.log('puntos orden', this.puntosOrden)
        for await (let puntos of this.puntosOrden) {
          console.log('init for')
          if (puntos.id > 0) {
            console.log('if en for')

            let puntoIni = puntos.coordenadas_inicio.split(',')
            let puntoFin = puntos.coordenadas_entrega.split(',')
            console.log('punto inicio', puntoini)
            console.log('punto fin', puntoFin)
            let endPoint = `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${puntoIni[1]}%2C${puntoIni[0]}%3B${puntoFin[1]}%2C${puntoFin[0]}?alternatives=true&geometries=geojson&language=en&overview=simplified&steps=true&access_token=pk.eyJ1IjoieWFxdWkiLCJhIjoiY2wzcjF3bXljMGh4czNibXZkY2tseWdnMiJ9.g7nt6H62ljJjIG72Kws1dA`
            //consumir servicio
            console.log('endPoint ////////////////////////', endPoint)
            const query = await fetch(endPoint, { method: 'GET' })

            const resp = await query.json()
            let coo = []
            let coo2 = []
            let cooTmp = []
            if (resp.routes.length > 1) {
              cooTmp = resp.routes[1].geometry.coordinates
            } else {
              cooTmp = resp.routes[0].geometry.coordinates
            }
            // alert(resp.routes.length)
            // cooTmp = resp.routes[1].geometry.coordinates

            let contador = 0
            for await (let itt of cooTmp) {
              let tt = {
                lng: itt[0],
                lat: itt[1],
              }
              coo.push(tt)
            }

            for await (let itt of cooTmp) {
              let tt1 = [itt[0], itt[1]]

              coo2.push(tt1)
            }

            // const geojson = {
            //   type: 'FeatureCollection',
            //   features: [
            //     {
            //       type: 'Feature',
            //       geometry: {
            //         type: 'LineString',
            //         properties: {},
            //         coordinates: coo2,
            //       },
            //     },
            //   ],
            // }

            // mapa.map.addLayer({
            //   id: 'route',
            //   type: 'line',
            //   source: {
            //     type: 'geojson',
            //     data: geojson,
            //     lineMetrics: true,
            //   },
            //   layout: {
            //     'line-join': 'round',
            //     'line-cap': 'round',
            //   },
            //   paint: {
            //     'line-color': '#0047AB',
            //     'line-width': 8,
            //   },
            // })
          }
        }

        //////////////////////////////////////////

        //calculando ruta de la pos del driver al punto A
        //obtener primer coordenada del driver
        let resseg = await this.$store.dispatch('get', { path: '/seguimiento_orden/getById/' + this.id })
        this.lat = resseg[0].lat
        this.lng = resseg[0].lng
        console.log('inicio coordenadsas -->', this.puntosOrden[1])
        let puntoini = this.puntosOrden[1].coordenadas_inicio
        puntoini = puntoini.split(',')
        console.log('inini puntoini', puntoini)
        let endPointDriver = `https://api.mapbox.com/directions/v5/mapbox/driving-traffic/${this.lng}%2C${this.lat}%3B${puntoini[1]}%2C${puntoini[0]}?alternatives=true&geometries=geojson&language=en&overview=simplified&steps=true&access_token=pk.eyJ1IjoieWFxdWkiLCJhIjoiY2wzcjF3bXljMGh4czNibXZkY2tseWdnMiJ9.g7nt6H62ljJjIG72Kws1dA`
        //consumir servicio
        const queryDriver = await fetch(endPointDriver, { method: 'GET' })
        const respDriver = await queryDriver.json()
        let cooDriver = []
        let cooTmpDriver = respDriver.routes[0].geometry.coordinates
        this.tiempo = (respDriver.routes[0].duration.toFixed(0) / 60).toFixed(2)
        this.distancia =
          respDriver.routes[0].distance < 1000
            ? respDriver.routes[0].distance.toFixed(2)
            : (respDriver.routes[0].distance / 1000).toFixed(2)
        console.log('lat lng', this.lat, ' ', this.lng)
        console.log('queryDriver', cooTmpDriver)

        let contadorDriver = 0
        for await (let it0 of cooTmpDriver) {
          let tt0 = {
            lng: it0[0],
            lat: it0[1],
          }
          cooDriver.push(tt0)
        }
        console.log('cooDriver ', cooDriver)

        ////////////
        for await (let it0 of cooTmpDriver) {
          let tt0 = {
            lng: it0[0],
            lat: it0[1],
          }
          cooDriver.push(tt0)
        }

        let cooDriver2 = []
        for await (let itt of cooTmpDriver) {
          let tt2 = [itt[0], itt[1]]
          cooDriver2.push(tt2)
        }
        console.log('coodriver ---->', cooDriver2)
        const geojson2 = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                properties: {},
                coordinates: cooDriver2,
              },
            },
          ],
        }

        // mapa.map.addLayer({
        //   id: 'route2',
        //   type: 'line',
        //   source: {
        //     type: 'geojson',
        //     data: geojson2,
        //     lineMetrics: true,
        //   },
        //   layout: {
        //     'line-join': 'round',
        //     'line-cap': 'round',
        //   },
        //   paint: {
        //     'line-color': '#FF0000',
        //     'line-width': 8,
        //   },
        // })

        ///////////

        // mapa.map.addPolyline({
        //   id: 2, // optional, can be used in 'removePolylines'
        //   color: '#FF0000', // Set the color of the line (default black)
        //   width: 8, // Set the width of the line (default 5)
        //   opacity: 0.6, //Transparency / alpha, ranging 0-1. Default fully opaque (1).
        //   points: cooDriver,
        // })

        ////////////////////
        // marcas
        let counter = 4
        let marcas = JSON.parse(localStorage.getItem('marcas')) || []
        let contapuntos = 1
        let imagen = ''

        if (marcas.length > 0) {
          this.marcas = marcas
        } else {
          // this.marcas = [
          //   {
          //     id: 0,
          //     coordinates: [this.lng, this.lat],
          //     lat: this.lat,
          //     lng: this.lng,
          //     title: 'y',
          //     icon: '/yaqui/driver.png',
          //     subtitle: 'mi posición actual',
          //   },
          // ]
          for await (let pmarcas of this.puntosOrden) {
            console.log(pmarcas.id)
            let ccord
            ccord = pmarcas.coordenadas_entrega.split(',')

            switch (contapuntos) {
              case 1:
                imagen = 'ayellow0.png'
                break
              case 2:
                imagen = 'byellow0.png' //"byellow0.png";
                break
              case 3:
                imagen = 'cyellow0.png'
                break
              case 4:
                imagen = 'dyellow0.png'
                break
              case 5:
                imagen = 'eyellow0.png'
                break
              case 6:
                imagen = 'fyellow0.png'
                break
              case 7:
                imagen = 'gyellow0.png'
                break
            }
            let data
            if (contapuntos == this.puntosOrden.length) {
              data = {
                id: contapuntos++,
                coordinates: [ccord[1], ccord[0]],
                lat: ccord[0],
                lng: ccord[1],
                title: 'Entrega',
                icon: '/yaqui/' + imagen,
                subtitle: 'mi posición actual',
                estado: false,
              }
            } else {
              data = {
                id: contapuntos,
                coordinates: [ccord[1], ccord[0]],
                lat: ccord[0],
                lng: ccord[1],
                title: pmarcas.nombre,
                icon: '/yaqui/' + imagen,
                subtitle: 'mi posición actual',
                estado: false,
              }
            }
            this.marcas.push(data)
            contapuntos++
          }
        }
        console.log('***************************** M A R C A S ******************** ', this.marcas)
        // mapa.map.addMarkers(this.marcas)
      } catch (error) {
        console.log('err *** ', error)
      }
    },
    async updateMap(Mymap) {
      console.log('mapa cargado...esperando')
      // paso 1 puntos historial
      const asyncActions = Mymap.component.actions
      this.center = this.coordinates[0].coordinates
      const newParams = await asyncActions.flyTo({
        center: this.center,
        zoom: 13,
        speed: 1,
      })
      // Here we cathing 'load' map event
      console.log('///////////////////****************')
      console.log(newParams)
      await this.showDataOrderInMap(Mymap)
    },
    async onMapLoad(event) {
      // this.Mymap = event
      this.updateMap(event)
    },

    showModal(type, item) {
      if (type == 1) {
        this.modalTitle = 'Nueva orden'
        this.row = {}
      } else {
        this.row = item
        this.modalTitle = 'Editar orden'
      }
      this.isDialogVisible = true
    },

    async getData() {
      // alesrt(this.id)
      try {
        this.loading = true
        let res = await this.$store.dispatch('get', { path: this.table + '/getById/' + this.id })
        if (res.length > 0) {
          this.items = res
        }
        //obtenemos data orden
        res = await this.$store.dispatch('get', { path: 'ordenPlantillaNotificacion/' + this.id })
        let resDataGereral = await this.$store.dispatch('get', { path: 'ordenes/getById/' + this.id })
        console.log('len data general', resDataGereral.length)
        this.resDataGereral = resDataGereral[0]
        console.log('------------', this.resDataGereral)

        this.loading = false
        if (res.length > 0) {
          this.dataOrder = res[0]
          this.puntosOrden = JSON.parse(this.dataOrder.puntos)
        }
      } catch (error) {
        this.dataOrder = []
        this.loading = false
        console.error('error', error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/apps/user.scss';
.bold {
  font-weight: bold;
}
</style>
